import React from 'react'
import FeaturesSplit from '../components/sections/FeaturesSplit'


const About = () => {
    return(
    <div>
        <FeaturesSplit/>
    </div>
    );
        
    
}

export default About;