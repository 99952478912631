import React from 'react'
import GenericSection from '../components/sections/GenericSection'


const About = () =>{
    return(
        <div>
            <GenericSection/>
        </div>
    )
}

export default About;